<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form ref="form" :rules="rules" :label-col="labelCol" :model="form" :wrapper-col="wrapperCol" class="form">
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="客户ID" name="userId">
              <a-input placeholder="请输入客户ID" v-model:value="form.userId" allow-clear></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phone">
              <a-input placeholder="请输入手机号" v-model:value="form.phone" allow-clear></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="真实姓名" name="realName">
              <a-input placeholder="请输入真实姓名" v-model:value="form.realName"></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证号" name="idCard">
              <a-input placeholder="请输入身份证号" v-model:value="form.idCard" allow-clear></a-input> </a-form-item></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="实名状态" name="realState">
              <a-select v-model:value="form.realState" placeholder="请选择实名状态">
                <a-select-option v-for="item in realNameList" :key="item.dictDataCode" :value="item.dictDataName">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select v-model:value="form.operatorId" placeholder="请选择所属运营商">
                <a-select-option v-for="item in operatorList" :key="item.operatorId" :value="item.operatorId">
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="pointId">
              <a-select v-model:value="form.pointId" placeholder="请选择所属租赁点">
                <a-select-option v-for="item in pointList" :key="item.pointId" :value="item.pointId">
                  {{ item.pointName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"><i class="iconfont icon-zaitu"></i>查询</a-button>
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button>
            <span v-if="!expand" @click="expandForm" style="color: #1890ff; cursor: pointer">
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span v-else @click="expandForm" style="color: #1890ff; cursor: pointer">
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table ref="table" row-key="userId" :columns="columns" :datasource="dataSource" @change="handleChange"
          :scroll="{ x: 'max-content' }">
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary" @click="exportClient()">
                <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
                <span>导出</span>
              </a-button>
              <!-- <a-button type="primary" danger @click="removeBatch">
                <template #icon>
                  <delete-outlined />
                </template>
                <span>删除</span>
              </a-button> -->
            </a-space>
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <a @click="openEdit(record)">修改</a>
              <!-- <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项目吗？">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm> -->
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  更多
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="balance" @click="addBalance(record)" v-if="$hasPermission(['rnt:balance:update'])">余额充值</a-menu-item>
                    <a-menu-item key="giveBalance" @click="addGiveBalance(record)" v-if="$hasPermission(['rnt:balance:update'])">赠送余额充值</a-menu-item>
                    <a-menu-item key="deposit" @click="addDeposit(record)" v-if="$hasPermission(['rnt:balance:update'])">押金充值</a-menu-item>
                    <a-menu-item key="thawDeposit" @click="thawDeposit(record)" v-if="$hasPermission(['rnt:balance:update'])">解冻押金</a-menu-item>
                    <a-menu-item key="phone" @click="alterPhoneNumber(record)">变更手机号</a-menu-item>
                    <a-menu-item key="log" @click="checkLog(record)">操作日志</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
          <template #userId="{ record }">
            <div style=" color: #409eff;text-decoration: underline;cursor: pointer;">
              {{ record.userId }}
            </div>
          </template>
          <template #contracteDeposit="{ record }">
            {{
              record.contracted === 0
              ? '未签约'
              : record.contracted === 1
                ? '已签约'
                : ''
            }}
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i class="iconfont icon-bukejian" style="cursor: pointer" v-if="!isShowOperator"
                @click="isShowOperator = true"></i>
              <i class="iconfont icon-kejian" style="cursor: pointer" v-else @click="isShowOperator = false"></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator && record.operatorName">
              {{
                record.operatorName.substring(0, 1) +
                new Array(record.operatorName.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operatorName }}
            </div>
          </template>
        </ud-pro-table>
      </div>
      <a-modal v-model:visible="visibleBalance" title="账户充值" @ok="handleBalanceOk" centered>
        <a-form ref="balanceForm" :model="balanceForm" :rules="balanceRules" :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-form-item label="请输入账户充值余额" name="balances">
            <a-input-number v-model:value="balanceForm.balances" placeholder="请输入余额充值金额" type="number"
              style="width: 100%">
            </a-input-number>
          </a-form-item>
          <a-form-item label="备注" name="remark">
            <a-textarea v-model:value="balanceForm.remark" placeholder="请输入备注">
            </a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleGiveBalance" title="账户充值" @ok="handleGiveBalanceOk" centered>
        <a-form ref="giveBalanceForm" :model="giveBalanceForm" :rules="giveBalanceRules" :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-form-item label="请输入赠送余额" name="balances">
            <a-input-number v-model:value="giveBalanceForm.balances" placeholder="请输入余额充值金额" type="number"
              style="width: 100%">
            </a-input-number>
          </a-form-item>
          <a-form-item label="备注" name="remark">
            <a-textarea v-model:value="giveBalanceForm.remark" placeholder="请输入备注">
            </a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleDeposit" title="账户充值" @ok="handleDepositOk" centered>
        <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" ref="depositForm" :model="depositForm"
          :rules="depositRules">
          <a-form-item label="请输入押金充值余额" name="balances">
            <a-input-number v-model:value="depositForm.balances" placeholder="请输入押金充值余额" type="number"
              style="width: 100%">
            </a-input-number>
          </a-form-item>
          <a-form-item label="备注" name="remark">
            <a-textarea v-model:value="depositForm.remark" placeholder="请输入备注">
            </a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleThawDeposit" title="解冻押金" @ok="handleThawDepositOk" centered>
        <div style="text-align: center; margin-bottom: 10px">
          确认为用户<span style="color: red">{{
            thawDepositForm.realName
          }}</span>进行押金解冻嘛?请确认用户订单已完结
        </div>
        <div style="text-align: center; margin-bottom: 20px">
          解冻押金金额
          <a-input-number :min="0" :max="max" v-model:value="thawDepositForm.deposit" style="color: red"></a-input-number>
          元资金将原路进行退还
        </div>
        <a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" :rules="thawDepositRules" ref="thawDepositForm"
          :model="thawDepositForm">
          <a-form-item label="扣除押金" name="deposit">
            <a-input-number v-model:value="thawDepositForm.balances" placeholder="请输入扣除押金" :min="0" :max="max"
              style="width: 100%">
            </a-input-number>
          </a-form-item>
          <a-form-item label="备注" name="remark">
            <a-textarea v-model:value="thawDepositForm.remark" placeholder="请输入备注">
            </a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visiblePhoneNumber" title="变更手机号" @ok="handlePhoneNumberOk" centered>
        <a-form :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }" ref="phoneForm" :model="phoneForm" :rules="phoneRules">
          <a-form-item label="手机号码" name="phone">
            <a-input v-model:value="phoneForm.phone" placeholder="请输入手机号码">
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleLog" title="操作日志" @ok="handleLogOk" centered :width="800" :footer="null">
        <!-- :scroll="{ y: 500 }"
          :pagination="pagination" -->
        <ud-pro-table ref="logTable" class="logForm" row-key="billId" :datasource="logData" :columns="logColumns" bordered
          :scroll="{ x: 'max-content' }">
        </ud-pro-table>
      </a-modal>
      <customer-detail v-model:visible="showDetail" :data="current || {}" />
      <customer-edit v-model:visible="showEdit" :data="current" @done="reload" />
    </a-card>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue'
import customerDetail from './customer-detail.vue'
import customerEdit from './customer-edit.vue'
import * as customerApi from '@/api/rnt/customer.js'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as dictDataApi from '@/api/rnt/realStateType.js'
import * as userBalanceApi from '@/api/sys/userBalanceBill.js'
import { toDateString } from '@/utils/util.js'
export default {
  components: {
    DownOutlined,
    // DeleteOutlined,
    customerDetail,
    customerEdit
  },
  data() {
    return {
      max: null,
      realNameList: [],
      operatorList: [],
      pointList: [],
      showDetail: false,
      showEdit: false,
      current: null,
      // logData: [
      //   // {
      //   //   actionContent: '充值金额380.0元',
      //   //   actionPerson: 'guowenjun',
      //   //   actionTime: '2022-01-12 15:46:38'
      //   // },
      //   // {
      //   //   actionContent: '充值押金4000.0元',
      //   //   actionPerson: 'guowenjun',
      //   //   actionTime: '2022-01-12 15:46:38'
      //   // },
      //   // {
      //   //   actionContent: '充值金额380.0元',
      //   //   actionPerson: 'guowenjun',
      //   //   actionTime: '2022-01-12 15:46:38'
      //   // },
      //   // {
      //   //   actionContent: '充值金额380.0元',
      //   //   actionPerson: 'guowenjun',
      //   //   actionTime: '2022-01-12 15:46:38'
      //   // }
      // ],
      logColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 65,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '操作内容',
          // align: 'center',
          dataIndex: 'billMsg'
        },
        {
          title: '操作人',
          // align: 'center',
          dataIndex: 'creater'
        },
        {
          title: '操作时间',
          align: 'center',
          width: 200,
          dataIndex: 'updateTime'
        }
      ],
      form: {
        // number: '',
        // model: '',
        // brand: '',
        // point: '',
        // operator: '',
        // state: '',
        // way:''
      },
      rules: {},
      balanceRules: {
        balances: [
          {
            type: 'number',
            required: true,
            message: '请输入充值余额',
            trigger: 'blur'
          }
        ]
      },
      giveBalanceRules: {
        balances: [
          {
            type: 'number',
            required: true,
            message: '请输入充值赠送余额',
            trigger: 'blur'
          }
        ]
      },
      depositRules: {
        balances: [
          {
            type: 'number',
            required: true,
            message: '请输入充值押金余额',
            trigger: 'blur'
          }
        ]
      },
      phoneRules: {
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          }
        ]
      },
      thawDepositRules: {
        balances: [
          {
            type: 'number',
            msssage: '请输入扣除押金'
          }
        ],
        remark: [
          {
            type: 'string',
            message: '请输入扣除押金备注',
            validator: (rule, value) => {
              if (this.thawDepositForm.balances) {
                rule.required = true
                if (!value) {
                  return Promise.reject(new Error(rule.message))
                } else {
                  return Promise.resolve()
                }
              } else {
                return Promise.resolve()
              }
            }
          }
        ]
      },
      labelCol: { md: { span: 8 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      expand: false,
      isShowOperator: false,
      pagination: {
        total: 0,
        defaultPageSize: 10,
        showTotal: (total) => `共 ${total} 条数据`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize)
      },
      selection: [], // 表格选中数据
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '客户ID',
          // width: 130,
          // align: 'center',
          dataIndex: 'userId',
          slots: {
            customRender: 'userId'
          }
        },
        {
          title: '手机号',
          // width: 120,
          // align: 'center',
          dataIndex: 'phone'
        },
        {
          title: '真实姓名',
          // width: 110,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '签约状态',
          // width: 110,
          // align: 'center',
          dataIndex: 'contracteDeposit',
          slots: {
            customRender: 'contracteDeposit'
          }
        },
        {
          title: '身份证号码',
          // width: 190,
          // align: 'center',
          dataIndex: 'idCard'
        },
        {
          title: '余额(元)',
          // width: 110,
          // align: 'center',
          dataIndex: 'balances'
        },
        {
          title: '赠送余额(元)',
          // width: 120,
          // align: 'center',
          dataIndex: 'giftAmount'
        },
        {
          title: '押金',
          // width: 100,
          // align: 'center',
          dataIndex: 'deposit'
        },
        {
          title: '注册时间',
          // width: 165,
          // align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '所属租赁点',
          // width: 165,
          // align: 'center',
          dataIndex: 'pointName'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'operatorName',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          dataIndex: 'action',
          slots: {
            customRender: 'action'
          }
        }
      ],
      visibleBalance: false, // 打开余额充值对话框
      visibleDeposit: false, // 打开押金充值对话框
      visiblePhoneNumber: false,
      visibleGiveBalance: false,
      visibleThawDeposit: false,
      visibleLog: false,
      balanceForm: {},
      depositForm: {},
      giveBalanceForm: {},
      phoneForm: {},
      thawDepositForm: {},
      logForm: {},
      logWhere: { userId: -1 }
    }
  },
  mounted() {
    this.queryOperater()
    this.queryPoint()
    this.queryRealState()
  },
  methods: {
    /* 查询所有实名状态 */
    queryRealState() {
      dictDataApi
        .realNameType()
        .then((res) => {
          if (res.code === 0) {
            this.realNameList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    logData(option, callback) {
      userBalanceApi
        .page({
          page: option.page,
          limit: option.limit,
          ...this.logWhere,
          ...this.logForm
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    dataSource(option, callback) {
      customerApi
        .page({
          ...this.form,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      // this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.form = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    // 批量删除表格选中数据
    removeBatch() { },
    // 充值余额
    addBalance(record) {
      this.visibleBalance = true
      this.balanceForm.userId = record.userId
    },
    // 充值赠送余额
    addGiveBalance(record) {
      this.visibleGiveBalance = true
      this.giveBalanceForm.userId = record.userId
    },
    // 充值押金
    addDeposit(record) {
      this.visibleDeposit = true
      this.depositForm.userId = record.userId
    },
    // 解冻押金
    thawDeposit(record) {
      this.visibleThawDeposit = true
      this.thawDepositForm.userId = record.userId
      this.thawDepositForm.deposit = record.deposit
      this.thawDepositForm.realName = record.realName
      this.thawDepositForm.phone = record.phone
      this.thawDepositForm.allDeposit = record.deposit
      this.max = record.deposit
    },
    // 变更手机号
    alterPhoneNumber(record) {
      this.visiblePhoneNumber = true
      this.phoneForm.userId = record.userId
    },
    // 查看操作日志
    checkLog(record) {
      this.visibleLog = true
      // this.logForm.userId = record.userId
      this.logWhere.userId = record.userId
      this.$refs.logTable.reload({ page: 1 })
    },
    // 余额充值完成回调事件
    handleBalanceOk() {
      this.$refs.balanceForm.validate().then(() => {
        customerApi
          .balance(
            Object.assign({}, this.balanceForm, {  
              payType: 'system',            
              billType: '余额充值'
            })
          )
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.visibleBalance = false
              this.reload()
              this.balanceForm = {}
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      })
    },
    // 赠送余额充值完成回调事件
    handleGiveBalanceOk() {
      this.$refs.giveBalanceForm.validate().then(() => {
        customerApi
          .giftAmount(
            Object.assign({}, this.giveBalanceForm, {
              payType: 'system',
              billType: '余额充值'
            })
          )
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.visibleGiveBalance = false
              this.reload()
              this.giveBalanceForm = {}
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      })
    },
    // 押金充值完成回调事件
    handleDepositOk() {
      this.$refs.depositForm.validate().then(() => {
        customerApi
          .deposit(
            Object.assign({}, this.depositForm, {
              payType: 'system',
              billType: '押金充值'
            })
          )
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.visibleDeposit = false
              this.reload()
              this.depositForm = {}
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      })
    },
    // 解冻押金完成回调事件
    handleThawDepositOk() {
      this.$refs.thawDepositForm.validate().then(() => {
        customerApi
          .unfreezingDeposit(
            Object.assign({}, this.thawDepositForm, {
              payType: 'system',
              billType: '押金解冻'
            })
          )
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.visibleThawDeposit = false
              this.reload()
              this.thawDepositForm = {}
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      })
    },
    // 手机号变更完成回调事件
    handlePhoneNumberOk() {
      this.$refs.phoneForm.validate().then(() => {
        customerApi
          .phone(this.phoneForm)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.visiblePhoneNumber = false
              this.phoneForm = {}
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() { },
    // 导出客户列表
    exportClient() {
      customerApi
        .exportClient(this.activityId, {
          ...this.where
        })
        .then((res) => {
          const { data, headers } = res
          const fileName =
            '客户信息-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => {})
    }
  }
}
</script>

<style lang='less' scoped>
.inputNumber {
  width: 100%;
}

.form {
  .btn-wrap {
    padding-right: 20px;

    .iconfont {
      margin-right: 5px;
    }
  }
}

.table {
  margin: 10px 0;
}

.logForm {
  :deep(.ud-table-tool) {
    display: none;
  }
}
</style>
