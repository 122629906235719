import request from '@/utils/request'

const api = {
  page: '/sys/userBalanceBill/page',
  dictData: '/sys/dictdata/page'
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询流水类型列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function billType() {
  return new Promise((resolve, reject) => {
    const params = {
      dictCode: 'billType'
    }
    request.get(api.dictData, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询支付方式列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function payType() {
  return new Promise((resolve, reject) => {
    const params = {
      dictCode: 'payType'
    }
    request.get(api.dictData, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
